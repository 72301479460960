import React, { FC, useEffect, useState } from 'react';
import classes from './GameView.module.scss';
import wsConn from '@envclient/envcore/src/service/WsConnection';

import PlayerList, { PlayerItemProps } from '@envclient/envcore/src/components/PlayerList';
import Button from '@envclient/envcore/src/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@envclient/envcore/src/reducers/store';
import {
  EndGameEventDTO,
  GameEventDTO,
  PlayerDTO,
  GameDTO,
  EnvDTO,
  GameConfigDTO,
  ActionDTO
} from '@envclient/envcore/src/types/core';
import { consumeGameEvent } from '@envclient/envcore/src/reducers/gameActions';
import WinnersDialog from '@envclient/envcore/src/components/WinnersDialog';
import { FaArrowLeft, FaArrowRight, FaLock, FaTimes, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { BiExpand, BiCollapse } from 'react-icons/bi';
import Chat from '@envclient/envcore/src/components/Chat';
import AddPlayersDialog from '@envclient/envcore/src/components/AddPlayersDialog';
import { useInterval } from 'react-use';
import classNames from 'classnames';
import bell from '../assets/bell3.mp3';
import { getUserInfo } from '../service/userService';
import { useMyPlayer } from '../hooks/useMyPlayer';

const AUTO_LEAVE_ENDED_TIMEOUT = 60000;
const AUTO_LEAVE_IDLE_TIMEOUT = 35000;

export interface GameViewProps {
  GameCanvas: FC<GameCanvasProps>;
  PlayerItem: FC<PlayerItemProps>;
}

export interface GameCanvasProps {
  env: EnvDTO<GameConfigDTO, PlayerDTO, ActionDTO>;
  game: GameDTO;
  myPlayer: PlayerDTO;
  active: boolean;
  onSubmit: (action?: ActionDTO) => void;
}

const GameView: FC<GameViewProps> = (props) => {
  const { GameCanvas, PlayerItem } = props;

  const user = useSelector((state: RootState) => state.user);
  const game = useSelector((state: RootState) => state.game.game);
  const env = useSelector((state: RootState) => state.game.env);
  const events = useSelector((state: RootState) => state.game.events) as GameEventDTO[];
  const myUser = useSelector((state: RootState) => state.user);

  const [winners, setWinners] = useState<PlayerDTO[]>([]);
  const [winnersOpen, setWinnersOpen] = useState<boolean>(false);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [addPlayersOpen, setAddPlayersOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [mute, setMute] = useState<boolean>(false);

  const dispatch = useDispatch();
  const room = game?.room;

  const active = useSelector((state: RootState) => {
    if (state.game.env?.actPlayer && state.user) {
      return state.game.env.actPlayer.id === state.user.userId;
    }
    return false;
  });
  const myPlayer = useMyPlayer();

  useEffect(
    function processEvents() {
      if (events?.length === 0) return;
      // process first END_GAME event in queue
      const endGameEvent = events.filter((e) => e.type === 'END_GAME')[0] as EndGameEventDTO;
      if (endGameEvent) {
        setWinners(endGameEvent.winners);
        setWinnersOpen(true);
        dispatch(consumeGameEvent(endGameEvent));
        getUserInfo();
      }
    },
    [events, dispatch]
  );

  useEffect(
    function autoStartGame() {
      if (!env) return;
      if (myUser?.type !== 'bot') return;
      if (game?.ownerId !== myUser?.userId) return;
      if (game.status !== 'IDLE') return;
      if (env.players?.length > 1) {
        console.log('autoStart');
        handleStart();
      }
    },
    [myUser, game?.ownerId, env, game]
  );

  useInterval(
    function autoLeaveIfNoOneJoins() {
      if (!env) return;
      if (myUser?.type !== 'bot') return;
      if (game?.ownerId !== myUser?.userId) return;
      if (env.players?.length > 1) return;
      handleLeave();
    },
    game?.status === 'IDLE' ? AUTO_LEAVE_IDLE_TIMEOUT : null
  );

  const onSubmit = (action?: ActionDTO) => {
    if (!active) return;
    wsConn.send({ type: 'UPDATE_ENV', selectedAction: action as ActionDTO });
  };

  // auto leave after game
  useInterval(
    () => {
      handleLeave();
    },
    game?.status == 'ENDED' ? AUTO_LEAVE_ENDED_TIMEOUT : null
  );

  // play sound when active
  useEffect(() => {
    if (active && game?.status === 'PLAYING') {
      playSound();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, game?.status]);

  // Watch for fullscreenchange
  useEffect(() => {
    function onFullscreenChange() {
      setFullscreen(Boolean(document.fullscreenElement));
    }
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  if (!game || !env) return null;

  const handleLeave = () => {
    if (!room || !user) return;
    wsConn.send({ type: 'LEAVE', room });
  };

  const handleStart = () => {
    wsConn.send({ type: 'UPDATE_GAME', event: { type: 'START_GAME' } });
  };

  const handleInvite = () => {
    setAddPlayersOpen(true);
  };

  const handleLocked = () => {
    wsConn.send({ type: 'UPDATE_GAME', event: { type: 'LOCK_GAME' } });
  };

  const handleShowChat = () => {
    setShowChat(true);
  };

  const playSound = () => {
    if (!mute) return;
    const audio = new Audio(bell);
    audio.play();
  };

  const handleExpand = () => {
    setExpanded(true);
  };

  const handleFullscreen = () => {
    //setFullscreen(!fullscreen);
    if (!fullscreen) {
      document.body.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleMute = () => {
    setMute(!mute);
  };

  const handleCollapse = () => {
    setExpanded(false);
  };

  const startBtnEnabled = game.status === 'IDLE' && game.ownerId === user?.userId;
  const startBtnVisible = game.status === 'IDLE';

  const { players = [] } = env;

  const chatContClasses = classNames(classes.chatCont, { [classes.show]: showChat });

  const gameContClasses = classNames(classes.gameCont, {
    [classes.expanded]: expanded,
    [classes.playing]: game.status === 'PLAYING'
  });

  return (
    <div className={gameContClasses}>
      <GameCanvas env={env} game={game} active={active} myPlayer={myPlayer} onSubmit={onSubmit} />

      <div className={classes.header}>
        <div className={classes.title}>{game.room.roomName}</div>
      </div>
      {startBtnVisible && (
        <div className={classes.startBtnContBg}>
          <div className={classes.startBtnCont}>
            <Button onClick={handleStart} disabled={!startBtnEnabled}>
              Start game
            </Button>
            <div className={classes.or}>or</div>
            {
              <Button onClick={handleInvite} disabled={!startBtnEnabled}>
                Invite players
              </Button>
            }
          </div>
        </div>
      )}
      <div className={classes.leaveBtnCont}>
        <Button secondary onClick={handleLeave}>
          <FaTimes />
        </Button>
      </div>
      <div className={classes.chatBtn}>
        <Button onClick={handleShowChat}>Chat</Button>
      </div>
      <div className={chatContClasses}>
        <Chat
          roomId={room?.roomId as number}
          showChat={showChat}
          toggleChat={() => setShowChat(false)}
          isPrivate={true}
        />
      </div>
      {players && (
        <PlayerList
          gameStatus={game.status}
          players={players}
          actPlayer={env?.actPlayer}
          myPlayer={myPlayer}
          delay={game.delay}
          PlayerItem={PlayerItem}
        />
      )}
      {winnersOpen && <WinnersDialog winners={winners} onClose={() => setWinnersOpen(false)} />}
      <div className={classes.rightBtnCont}>
        <Button onClick={handleLocked} small disabled={game.locked}>
          <FaLock />
        </Button>
        <Button onClick={handleFullscreen} small>
          {fullscreen ? <BiCollapse /> : <BiExpand />}
        </Button>
        <Button onClick={handleMute} small>
          {mute ? <FaVolumeUp /> : <FaVolumeMute />}
        </Button>
      </div>
      <div className={classes.leftBtnCont}>
        {expanded ? (
          <Button onClick={handleCollapse} small>
            <FaArrowRight />
          </Button>
        ) : (
          <Button onClick={handleExpand} small>
            <FaArrowLeft />
          </Button>
        )}
      </div>
      {addPlayersOpen && <AddPlayersDialog onClose={() => setAddPlayersOpen(false)} />}
    </div>
  );
};

export default GameView;
