import { CcsonFamilyTypeDTO, CcsonGameTypeDTO, TileGameConfig } from '../types/ccson_env';
import { KFamilyTypeDTO, KGameTypeDTO } from '../types/koenigsburg_env';
import ccsonGameConfigJson from './ccson_config.json';
import koenigsburgGameConfigJson from './koenigsburg_config.json';

// create union types - Java export doesnt make the link between them
export type TileBasedGameType = CcsonGameTypeDTO | KGameTypeDTO;
export type TileBasedGameFamilyType = CcsonFamilyTypeDTO | KFamilyTypeDTO;

export const ccsonGameConfigs: Array<TileGameConfig> = ccsonGameConfigJson as TileGameConfig[];
export const koenigsburgGameConfigs: Array<TileGameConfig> = koenigsburgGameConfigJson as TileGameConfig[];
