import React, { FC, useEffect, useState } from 'react';
import { calculateMeeplePos } from '../ccson/TileItem';
import classes from './KTileMap.module.scss';
import { CSSTransition } from 'react-transition-group';
import { CcsonEventDTO, MeepleDTO, MeepleType, RecoveredMeeplesDTO } from '../../types/ccson_env';
import { CSSPos, Pos } from '@envclient/envcore/src/types/frontendTypes';
import { cloneObject } from '@envclient/envcore/src/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@envclient/envcore/src/reducers/store';
import { consumeGameEvent } from '@envclient/envcore/src/reducers/gameActions';
import { GameEventDTO } from '@envclient/envcore';
import PrismaZoom from '@envclient/envcore/src/components/PrismaZoom';
import { TileBasedGameFamilyType } from '../../config/tileGameConfig';
import { getTileHeight, getTileWidth } from './KTileAsset';
import Button from '@envclient/envcore/src/components/Button';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { FiRotateCcw } from 'react-icons/fi';
import { useMyPlayer } from '@envclient/envcore/src/hooks/useMyPlayer';
import MeepleCont from '../ccson/MeepleCont';
import { TileDef, getTileType } from '../../config/tileMapConfig';
import { KActionDTO, KEnvDTO, KPlayerDTO, KTileDTO, TileDTO } from '../../types/koenigsburg_env';
import Meeple from '../ccson/Meeple';
import KTileItem from './KTileItem';
import { SelectPosActions } from '../ccson/TileMap';
import KTilePlaceholder from './KTilePlaceholder';
import OverlayCont from './OverlayCont';
import TileAnchor from '../ccson/TileAnchor';

export type Positions = Record<string, { coord: string; actions: KActionDTO[]; x: number; y: number }>;
export const toCoord = (p: Pos) => p.x + ':' + p.y;

interface KTileMapProps {
  tileData: Array<KTileDTO>;
  overlayData: Array<KTileDTO>;
  currentActions: Array<KActionDTO>;
  onSubmit: (action?: KActionDTO) => void;
  active: boolean;
  lastTile: KTileDTO;
}

const KTileMap: FC<KTileMapProps> = (props) => {
  const { tileData, overlayData, currentActions, active, lastTile, onSubmit } = props;

  const game = useSelector((state: RootState) => state.game.game);

  const submitBtnEnabled = active && game?.status === 'PLAYING';

  const [selectedAction, setSelectedAction] = useState<KActionDTO | null>(null);

  const [recoveredMeeplesEvent, setRecoveredMeeples] = useState<RecoveredMeeplesDTO | null>(null);
  const events = useSelector((state: RootState) => state.game.events) as unknown as CcsonEventDTO[];
  const env = useSelector((state: RootState) => state.game.env) as KEnvDTO;
  const family = env.gameConfig.family as TileBasedGameFamilyType;
  const dispatch = useDispatch();
  const [tilePositions, setTilePositions] = useState<Positions>({});

  const myPlayer: KPlayerDTO = useMyPlayer() as KPlayerDTO;
  const { meeples } = myPlayer;
  const [preferredMeepleType, setPreferredMeepleType] = useState<MeepleType>();

  const [selectedPos, setSelectedPos] = useState<Pos | null>(null);
  const [posActions, setPosActions] = useState<KActionDTO[] | null>(null);

  const [zoom, setZoom] = useState<number>(0.5);

  const tileWidth = getTileWidth(family);
  const tileHeight = getTileHeight(family);

  useEffect(
    function groupCurrentActionsByPosition() {
      // clear state
      setSelectedAction(null);
      const tilePositions: Positions = {};
      //split this into 2 arrays - tilePositions and overlayPositions
      // TODO dont split - render actions instead tiles - an action will have tile + overlay inside
      currentActions.forEach((a) => {
        const { tileDTO } = a;
        if (tileDTO.x !== null || tileDTO.y !== null) {
          const coord = tileDTO.x + ':' + tileDTO.y;
          if (!tilePositions[coord]) {
            tilePositions[coord] = { coord, actions: [], x: tileDTO.x, y: tileDTO.y };
          }
          tilePositions[coord].actions.push(a);
        }
      });
      setTilePositions(tilePositions);
    },
    [currentActions]
  );

  const tilePlaces = Object.keys(tilePositions).map((k) => tilePositions[k]);

  //reset state when action changes
  useEffect(
    function syncSelectedAction() {
      if (!selectedAction) {
        setSelectedPos(null);
        setPosActions(null);
      }
    },
    [selectedAction, tilePositions]
  );

  useEffect(
    function processEvents() {
      if (events?.length === 0) return;
      // process first RECOVERED_MEEPLES event in queue
      // TODO process events in diff slices by type ?
      const meeplesEvent = events.filter((e) => e.type === 'RECOVERED_MEEPLES')[0] as RecoveredMeeplesDTO;
      if (!meeplesEvent) return;
      setRecoveredMeeples(meeplesEvent);
      dispatch(consumeGameEvent(meeplesEvent as unknown as GameEventDTO));
    },
    [events, dispatch]
  );

  const tilePlaceholderClick = ({ selectedPos, posActions }: SelectPosActions) => {
    const defaultAction = posActions[0];
    console.log('tile placeHolder click', posActions.length);
    setSelectedAction(defaultAction as KActionDTO);
    setSelectedPos(selectedPos);
    setPosActions(posActions as KActionDTO[]);
  };

  const lastTileClick = (selectedAction: KActionDTO) => {
    console.log('lastTileClick', selectedAction);
    setSelectedAction(selectedAction as KActionDTO);
  };

  const lastMeepleClick = (meeple: MeepleDTO) => {
    if (!posActions?.length) return;
    const defaultAction = posActions[0];
    setSelectedAction(defaultAction as KActionDTO);
    // TODO play send back animation
    console.log('meepleClick', meeple);
  };

  const handleZoomChange = (value: number) => {
    setZoom(value);
  };

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.1);
  };

  const handleRotate = () => {
    if (!posActions || !selectedAction) return;
    // try to rotate forw
    let nextRotPos = posActions.find((action: KActionDTO) => action.tileDTO?.rot > selectedAction.tileDTO?.rot);
    //try to rotate backw
    if (!nextRotPos)
      nextRotPos = posActions.find((action: KActionDTO) => action.tileDTO.rot < selectedAction.tileDTO.rot);
    if (nextRotPos) {
      setSelectedAction(nextRotPos);
    }
  };

  const handleMeepleTypeChange = (type: MeepleType | undefined) => {
    setPreferredMeepleType(type);
  };

  const handleSubmit = () => {
    if (!active) return;
    if (!selectedAction) return;
    onSubmit(selectedAction);
    setSelectedAction(null);
  };

  const handleSkip = () => {
    if (!active) return;
    onSubmit(undefined);
    setSelectedAction(null);
  };

  if (!tileData) return null;

  return (
    <div className={classes.tileMap}>
      <PrismaZoom scrollVelocity={0.05} currentZoom={zoom} onZoomChange={handleZoomChange}>
        <div className={classes.canvas}>
          <div className={classes.bg}></div>
          {tileData.map((t, index) => {
            return <KTileItem active={false} key={t.id + '_' + index} tile={t} last={index === tileData.length - 1} />;
          })}
          {overlayData.map((t, index) => {
            return <KTileItem active={false} key={t.id + '_' + index} tile={t} last={false} />;
          })}
          {active &&
            tilePlaces.map((p) => (
              <KTilePlaceholder
                key={p.coord}
                x={p.x}
                y={p.y}
                actions={p.actions}
                onClick={tilePlaceholderClick}
                family={family}
              />
            ))}
          {active && selectedAction && posActions && (
            <KTileItem
              key={selectedAction.tileDTO?.id + '_active'}
              active={true}
              tile={selectedAction.tileDTO as TileDTO}
              posActions={posActions}
              onClick={lastTileClick}
              onMeepleClick={lastMeepleClick}
              preferredMeepleType={preferredMeepleType}
              selectedAction={selectedAction}
            />
          )}
          {recoveredMeeplesEvent?.recoveredMeeples.map((m) => {
            const meeple = cloneObject(m);
            meeple.recovered = false;
            // tile offset
            const t = tileData.filter(
              (t) => t.meeple?.playerId === meeple.playerId && t.meeple?.recovered && t?.meeple?.id === meeple.id
            )[0];
            if (!t || !t.meeple) return null;
            // ccson specific
            let left = t.x * tileWidth;
            let top = t.y * tileHeight;
            // add meeple offset
            const tileDef: TileDef = getTileType(t.type, family);
            const nodes = tileDef.nodes;
            const meepleNode = nodes.find((node) => node.id === meeple.nodeId);
            const meepleOffset = calculateMeeplePos(meepleNode, t.rot, family) as CSSPos;
            console.log('recoveredMeepleOffset', t, left, top);
            left += meepleOffset.left as number;
            top += meepleOffset.top as number;
            const tileStyle = { width: 40, position: 'absolute' as const, left, top };
            return (
              <CSSTransition
                key={meeple.id}
                in={true}
                appear={true}
                timeout={3000}
                classNames="recover"
                unmountOnExit
                onEntered={() => {
                  setRecoveredMeeples(null);
                }}>
                <div style={tileStyle}>
                  <Meeple meeple={meeple} />
                </div>
              </CSSTransition>
            );
          })}
        </div>
      </PrismaZoom>
      {myPlayer && <MeepleCont color={myPlayer.color} meeples={meeples} onMeepleTypeChange={handleMeepleTypeChange} />}
      {myPlayer && <OverlayCont overlays={myPlayer.overlays} />}
      <div className={classes.rightBtnCont}>
        <Button onClick={handleZoomIn} small>
          <FaPlus />
        </Button>
        <Button onClick={handleZoomOut} small>
          <FaMinus />
        </Button>
        <Button onClick={handleRotate} small>
          <FiRotateCcw />
        </Button>
      </div>
      <TileAnchor zoom={zoom} dragEnabled={false}>
        {lastTile && active && !selectedPos && <KTileItem active={true} tile={lastTile} />}
      </TileAnchor>
      <div className={classes.submitBtn}>
        <Button onClick={handleSubmit} disabled={!submitBtnEnabled}>
          Submit
        </Button>
      </div>
      <div className={classes.skipBtn}>
        <Button onClick={handleSkip} disabled={!submitBtnEnabled}>
          Skip
        </Button>
      </div>
    </div>
  );
};

export default React.memo(KTileMap);
